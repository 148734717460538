import React from 'react';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import theme from './styles/theme';
import NavbarComponent from './components/Navbar/NavbarComponent';
import FooterComponent from './components/Footer/FooterComponent';
import HomePage from './pages/Home';
import DropdownManagement from './pages/DropdownManagementPage';

// Custom hook for tracking page views
const usePageTracking = () => {
  const location = useLocation();

  React.useEffect(() => {
    // Pushing data to the GTM dataLayer with the new page path
    window.dataLayer.push({
      event: 'pageview',
      page: location.pathname + location.search,
    });
  }, [location]);
};

const RouteChangeTracker = () => {
  usePageTracking();
  return null;
};

const App = () => {
  const isInIframe = () => {
    return window.location !== window.parent.location;
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <RouteChangeTracker /> {/* Include the tracker here */}
        {!isInIframe() && <NavbarComponent />}
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/manage-dropdowns" element={<DropdownManagement />} />
          {/* Add other routes as needed */}
        </Routes>
        {!isInIframe() && <FooterComponent />}
      </Router>
    </ThemeProvider>
  );
};

export default App;
