import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAW2FoGkDSnTcdlF_fdX0o1d-tvcSXkv6k",
  authDomain: "adobecidbuilder-3235c.firebaseapp.com",
  projectId: "adobecidbuilder-3235c",
  storageBucket: "adobecidbuilder-3235c.appspot.com",
  messagingSenderId: "412223688977",
  appId: "1:412223688977:web:4279e6a00a00b344affcb0",
  measurementId: "G-ZV5VHBKVY8"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

export { db };